import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { Element } from "react-scroll";

import OrangeButton from "../components/OrangeButton";

const Books = ({ books }) => {
	return (
		<div className="books container" id="books">
			<h2 className="page-title">All My Books</h2>
			{books.map(book => {
				return (
					<Element className="book-wrapper" key={book._id} id={book.slug}>
						<div className="book-cover">
							<img src={book.mainImage.asset.url} alt="RemnantsOfNight" />
						</div>
						<div className="book-text">
							<h2 className="book-title">{book.title}</h2>
							<BlockContent blocks={book.longDescription} projectId="uqsuxcaz" dataset="production" />
							<OrangeButton link={book.link} text="Buy on Amazon" target="_blank" />
						</div>
					</Element>
				);
			})}
		</div>
	);
};

export default Books;
