import React from "react";
import { Link } from "react-router-dom";

const OrangeButton = ({ link, text, moreStyles, target }) => {
	if (target === "_blank") {
		return (
			<a className="primaryButton" rel="noreferrer" target="_blank" href={link} style={moreStyles}>
				{text}
			</a>
		);
	} else {
		return (
			<Link className="primaryButton" to={link} style={moreStyles}>
				{text}
			</Link>
		);
	}
};

export default OrangeButton;
