import React from "react";
import BlockContent from "@sanity/block-content-to-react";

import SocialMediaRow from "../components/SocialMediaRow";

const About = ({ aboutInfo }) => {
	return (
		<div className="about container" id="about">
			<h2 className="page-title">A Little About Me</h2>
			<div className="about_wrapper">
				<div className="about_content">
					{aboutInfo[0]?.bio && (
						<BlockContent blocks={aboutInfo[0].bio} projectId="uqsuxcaz" dataset="production" />
					)}
				</div>
				<div className="about_img">
					<img className="avatar" src={aboutInfo[0]?.mainImage.asset.url} alt="Keri" />
					<SocialMediaRow />
				</div>
			</div>
		</div>
	);
};

export default About;
