import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
	const { width } = useWindowDimensions();
	const [menu, setMenu] = useState(false);

	useEffect(() => {
		if (width > 768 && menu) {
			setMenu(!menu);
		}
	}, [width, menu]);

	function toggleMenu() {
		setMenu(!menu);
	}

	return (
		<nav id="navbarTop">
			<div className="nav-container">
				<div className="logo">
					<NavLink to="/">Keri L. Salyers</NavLink>
				</div>
				<button className="menu-button" onClick={toggleMenu}>
					<FontAwesomeIcon icon={faBars} />
				</button>
				<div className={menu ? "nav-links-m" : "nav-links"}>
					<ul>
						<li>
							<NavLink
								to="/about"
								onClick={width <= 768 ? () => toggleMenu() : null}
								activeStyle={{
									color: "#ff7b01",
								}}>
								About
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/books"
								onClick={width <= 768 ? () => toggleMenu() : null}
								activeStyle={{
									color: "#ff7b01",
								}}>
								Books
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/updates"
								onClick={width <= 768 ? () => toggleMenu() : null}
								activeStyle={{
									color: "#ff7b01",
								}}>
								Updates
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/contact"
								onClick={width <= 768 ? () => toggleMenu() : null}
								activeStyle={{
									color: "#ff7b01",
								}}>
								Contact
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
