import React, { useEffect } from "react";
import LoadingIconTwo from "../components/LoadingIconTwo";

const Updates = () => {
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://platform.twitter.com/widgets.js";
		document.getElementsByClassName("updates")[0].appendChild(script);
	}, []);

	return (
		<div className="updates container" id="updates">
			<h2 className="page-title">Updates</h2>
			<a
				className="twitter-timeline"
				data-theme="dark"
				data-tweet-limit="5"
				data-width="820"
				data-dnt="true"
				data-chrome="noheader nofooter noborders"
				href="https://twitter.com/KeriLSalyers">
				<LoadingIconTwo />
			</a>
		</div>
	);
};

export default Updates;
