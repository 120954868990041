import React from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const Footer = () => {
	const scrollToTop = () => {
		scroll.scrollToTop();
	};

	return (
		<footer id="footer">
			<button className="scroll-to-top" onClick={scrollToTop}>
				Back to top
			</button>
			<div className="footer-content">
				<h3>Keri L. Salyers</h3>
				<ul>
					<li>
						<Link to="/about">About</Link>
					</li>
					<li>
						<Link to="/books">Books</Link>
					</li>
					<li>
						<Link to="/updates">Updates</Link>
					</li>
					<li>
						<Link to="/contact">Contact</Link>
					</li>
				</ul>
			</div>
		</footer>
	);
};

export default Footer;
