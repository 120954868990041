import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import sanityClient from "./sanity";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Updates from "./pages/Updates";
import Books from "./pages/Books";

const App = () => {
	const [loading, setLoading] = useState(false);
	const [intro, setIntro] = useState([]);
	const [books, setBooks] = useState([]);
	const [aboutInfo, setAboutInfo] = useState([]);

	useEffect(() => {
		document.cookie = "sameSite=None; Secure";
		setLoading(true);

		async function getData() {
			const books = await sanityClient.fetch(
				`*[_type == "book"] {
					_id,
					title,
					slug,
					shortDescription,
					longDescription,
					link,
					mainImage{
						asset->{
							_id,
							url
						},
					}
				}`
			);
			const intros = await sanityClient.fetch(
				`*[_type == "homeIntro"] {
						_id,
						fullName,
						quote,
						personality,
						genre,
						bio,
						mainImage{
							asset->{
								_id,
								url
							},
						},
					}`
			);
			const about = await sanityClient.fetch(
				`*[_type == "aboutPage"] {
					_id,
					bio,
					mainImage{
						asset->{
							_id,
							url
						},
					}
				}`
			);
			setIntro(intros);
			setBooks(books);
			setAboutInfo(about);
			setTimeout(() => {
				setLoading(false);
			}, 1100);
		}
		getData();
	}, []);

	return (
		<div className="app">
			{loading ? (
				<div className="loader-wrapper">
					<ul role="progressbar" aria-busy="true" aria-label="Loading domino shop">
						<li role="presentation"></li>
						<li role="presentation"></li>
						<li role="presentation"></li>
						<li role="presentation"></li>
						<li role="presentation"></li>
						<li role="presentation"></li>
						<li role="presentation"></li>
					</ul>
				</div>
			) : (
				<>
					<Navbar />
					<ScrollToTop />
					<Switch>
						<Route path="/about">
							<About aboutInfo={aboutInfo} />
						</Route>
						<Route path="/books">
							<Books books={books} />
						</Route>
						<Route path="/updates">
							<Updates />
						</Route>
						<Route path="/contact">
							<Contact />
						</Route>
						<Route path="/">
							<Home books={books} intro={intro} />
						</Route>
					</Switch>
					<Footer />
				</>
			)}
		</div>
	);
};

export default App;
