import React, { useState } from "react";
import LoadingIcon from "../components/LoadingIcon";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import ContactIllustration from "../assets/ContactSVG.svg";

const Contact = () => {
	const [loading, setLoading] = useState(false);
	const { register, errors, handleSubmit, reset } = useForm();

	const toastifySuccess = () => {
		toast("🚀 Form sent! Thank you!", {
			position: "bottom-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};

	const onSubmit = async data => {
		try {
			setLoading(true);
			const templateParams = {
				from_name: data.name,
				reply_to: data.email,
				message: data.message,
			};

			await emailjs.send(
				"service_5n9blom",
				"template_ujuce0v",
				templateParams,
				"user_0oGnlZx2NzmNTje1J33qw"
			);
			reset();
			toastifySuccess();
			setLoading(false);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div id="contact" className="contact container">
			<h2 className="page-title">Contact Me</h2>
			<div className="contact-body">
				<div className="contact-body-left">
					<form id="gform" name="gform" onSubmit={handleSubmit(onSubmit)} noValidate>
						<label>
							<p>Full Name:*</p>
							<input
								type="text"
								name="name"
								id="name"
								placeholder="Full Name"
								ref={register({
									required: { value: true, message: "Please enter your name" },
									maxLength: {
										value: 30,
										message: "Please use 30 characters or less",
									},
								})}
							/>
						</label>
						<small className="error-msg error">
							{errors.name && <span className="errorMessage">{errors.name.message}</span>}
						</small>

						<label>
							<p>Your Email:*</p>
							<input
								type="email"
								name="email"
								ref={register({
									required: true,
									pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
								})}
								placeholder="example@example.com"
							/>
						</label>
						<small className="error-msg error">
							{errors.email && <span className="errorMessage">Please enter a valid email address</span>}
						</small>

						<label>
							<p>Your message:*</p>
							<textarea
								name="message"
								ref={register({
									required: true,
								})}
								cols="30"
								rows="8"
								placeholder="Hi!"></textarea>
						</label>
						<small className="error-msg error">
							{errors.message && <span className="errorMessage">Please enter a message</span>}
						</small>

						{loading ? (
							<button disabled id="contactButton">
								<LoadingIcon />
							</button>
						) : (
							<button type="submit" id="contactButton">
								Send Message
							</button>
						)}
					</form>
					<ToastContainer />
				</div>
				<img src={ContactIllustration} alt="contact illustration" />
			</div>
		</div>
	);
};

export default Contact;
