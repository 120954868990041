import React from "react";
import Amazon from "../assets/amazon.svg";
import Email from "../assets/email.svg";
import Goodreads from "../assets/goodreads.svg";
import Twitter from "../assets/twitter.svg";

const SocialMediaRow = () => {
	return (
		<div className="social-media-row">
			<a href="https://twitter.com/KeriLSalyers" target="_blank" rel="noreferrer">
				<img src={Twitter} alt="twitter" />
			</a>
			<a href="mailto:contact@klsalyers.com">
				<img src={Email} alt="email" />
			</a>
			<a href="https://www.amazon.com/Keri-L-Salyers/e/B00IZPI7WK" target="_blank" rel="noreferrer">
				<img src={Amazon} alt="amazon" />
			</a>
			<a
				target="_blank"
				rel="noreferrer"
				href="https://www.goodreads.com/author/show/7906093.Keri_L_Salyers">
				<img src={Goodreads} alt="goodreads" />
			</a>
		</div>
	);
};

export default SocialMediaRow;
