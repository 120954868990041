import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation, Pagination, EffectFade, Autoplay } from "swiper/core";
import { HashLink as Link } from "react-router-hash-link";
import BlockContent from "@sanity/block-content-to-react";

import SocialMediaRow from "../components/SocialMediaRow";
import OrangeButton from "../components/OrangeButton";

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

const Home = ({ books, intro }) => {
	return (
		<div id="home">
			<Swiper
				pagination={{
					clickable: true,
				}}
				spaceBetween={10}
				navigation={true}
				loop={true}
				effect={"fade"}
				autoplay={{
					delay: 4500,
					disableOnInteraction: true,
				}}
				className="mySwiper">
				{books.map(book => {
					return (
						<SwiperSlide key={book._id}>
							<div
								className="img-bg"
								style={{
									background: `linear-gradient(to right, rgba(167, 167, 167, 0.308), rgba(167, 167, 167, 0.308)), url('${book.mainImage.asset.url}')`,
								}}>
								<div className="carousel-content">
									<img src={book.mainImage.asset.url} alt={book.title} />
									<div className="book-content">
										<h2>{book.title}</h2>
										<BlockContent
											blocks={book.shortDescription}
											projectId="uqsuxcaz"
											dataset="production"
										/>
										<Link to={`/books#${book.slug}`}>Learn More</Link>
									</div>
								</div>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>

			<div className="banner">
				<h2>{intro[0]?.quote}</h2>
			</div>

			<div className="short-intro container">
				<div className="short-intro_content">
					<h2>
						{intro[0]?.fullName} <span>{intro[0]?.personality}</span>
					</h2>
					<p className="quote">{intro[0]?.genre}</p>

					{intro[0]?.bio && (
						<BlockContent blocks={intro[0]?.bio} projectId="uqsuxcaz" dataset="production" />
					)}

					<OrangeButton
						link="/about"
						text="Learn more"
						moreStyles={{ borderRadius: "5px", fontSize: "18px" }}
					/>
				</div>
				<div className="short-intro_img">
					<img src={intro[0]?.mainImage.asset.url} alt="Keri" className="profile-pic" />
					<SocialMediaRow />
				</div>
			</div>
		</div>
	);
};

export default Home;
